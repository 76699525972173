import React, { FC } from 'react';
import styleObj from './download.module.less';
import { useTranslation } from 'react-i18next';
import img1 from '../../assets/images/1.png';
import { useAndroid, useSmallWindow } from '../../hooks';
import { Link } from 'react-router-dom';

const Download: FC<{ isSimple?: boolean }> = ({ isSimple = false }) => {
  const { t, i18n: { language } } = useTranslation();
  const [isMobile] = useSmallWindow();
  const [isAndroid] = useAndroid();

  return (
    <div className={styleObj.bottom}>
      <div className={styleObj.up}>
        <div className={styleObj.left}>
          <Link to="/">
            <img
              className={styleObj.img}
              src="https://auto.omicdn.com/v1/images/eyJpZCI6IkQ2SjNQM1pVRDdDTk1HN1ZZR05NRlhJR1hONTdEUSIsInciOjEyNCwiaCI6NjAsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjoxMjE5MDU3ODI0MTk1MjEwODg2NCwiYWIiOjB9?format=originalOFGHLERTH"
              alt=""
            />
          </Link>
          <div>{t('download.text1')}</div>
          <div>
            {`${t('download.text2')}\n${t('download.text3')}`}
            <img src={img1} style={{ height: 16 }} alt="" />
          </div>
          <div>{t('download.text4')}</div>
          <div>
            <a href="https://www.instagram.com/explore/tags/omiapp/?hl=en" target="_blank" rel="noreferrer">
              <img
                src="https://auto.omicdn.com/v1/images/eyJpZCI6IjJXVVg3NlQzTk9QVldMWkFFMlhLRUJaUU5UMkROViIsInciOjY4LCJoIjo2OCwiZCI6MCwibXQiOiJpbWFnZS9qcGVnIiwiZGgiOjEwODUxNDY3Mjc4NTg5MDI3OTksImFiIjowfQ?format=originalOFGHLERTH"
                alt=""
              />
            </a>
            <a href="https://www.tiktok.com/@omi.malaysia" target="_blank" rel="noreferrer">
              <img
                src="https://auto.omicdn.com/v1/images/eyJpZCI6IjNSSk9BNlNJUkVNMkVUQk9TQktUR0hWMldKUEM0TyIsInciOjY4LCJoIjo2OCwiZCI6MCwibXQiOiJpbWFnZS9qcGVnIiwiZGgiOjIyNDY1NjEwMzA5MDIyMDUxOTEsImFiIjowfQ?format=originalOFGHLERTH"
                alt=""
              />
            </a>
            <img
              src="https://auto.omicdn.com/v1/images/eyJpZCI6IjY3RU5DR0wyNUxYWUtVS1EyNVJLQ1ZHTE9SQUhVSCIsInciOjY4LCJoIjo2OCwiZCI6MCwibXQiOiJpbWFnZS9qcGVnIiwiZGgiOjE2NjE2NTgyOTEwMDMwMDU3MTEsImFiIjowfQ?format=originalOFGHLERTH"
              alt=""
            />
            <img
              src="https://auto.omicdn.com/v1/images/eyJpZCI6IldMVUVNSTJSUklTVUFTQVA3Tk1DQTRVNUZWWEpGUyIsInciOjY4LCJoIjo2OCwiZCI6MCwibXQiOiJpbWFnZS9qcGVnIiwiZGgiOjIyNDQ4NDU5NzIwMzA3MTEwNTUsImFiIjowfQ?format=originalOFGHLERTH"
              alt=""
            />
          </div>
        </div>
        {isMobile && <div className={styleObj.line} />}
        <div className={styleObj.middle}>
          <div className={styleObj.title}>{t('download.text5')}</div>
          <div><a href={'/company'}>{t('download.text6')}</a></div>
          <div><a href={'/company/join'}>{t('download.text7')}</a></div>
          <div className={styleObj.title}>{t('download.text11')}</div>
          <div><a href="/why" target="_blank">{t('download.text12')}</a></div>
          <div><a href={`/norms/${language}`} target="_blank" rel="noreferrer">{t('download.text13')}</a></div>
          <div><a href="/help" target="_blank">{t('download.text14')}</a></div>
        </div>

        <div className={styleObj.right}>
          <div className={styleObj.title}>{t('download.text8')}</div>
          <div><a href="/features/toolkit">{t('download.text9')}</a></div>
          <div><a href="/features/subscription">{t('download.text10')}</a></div>
          <div className={styleObj.title}>{t('download.text15')}</div>
          <div><a href="/support">{t('download.text16')}</a></div>
          <div><a href="/press">{t('download.text17')}</a></div>
          <div><a href="https://omiapp.onelink.me/HCKs/g7zp1mmz">{t('download.text18')}</a></div>
        </div>
      </div>
      {
                !isMobile &&
                <>
                  <div className={styleObj.line} />
                  <div className={styleObj.down}>
                    <div className={styleObj.down_top}>
                      <div>{t('home.getApp')}</div>
                      {
                        <a target="_blank" href="https://omiapp.onelink.me/HCKs/g7zp1mmz" rel="noreferrer">
                          <img
                            src="https://auto.omicdn.com/v1/images/eyJpZCI6IjVYUlNEUkxaTkNQNlJCTloySUJCQ0xBTVhTWUM1ViIsInciOjM1MCwiaCI6MTAwLCJkIjowLCJtdCI6ImltYWdlL2pwZWciLCJkaCI6MTIzNzQ1MzQxNDE0Nzg0MCwiYWIiOjB9?format=originalOFGHLERTH"
                            alt=""
                          />

                        </a>
                            }
                      {
                        <a
                          target="_blank"
                          href="https://play.google.com/store/apps/details?id=sg.omi&hl=en&gl=US"
                          rel="noreferrer"
                        >
                          <img
                            src="https://auto.omicdn.com/v1/images/eyJpZCI6IldOSjVRUk5IUVBWVUZQRkRUUE5BUkhTVE5IWk1KVSIsInciOjM1MCwiaCI6MTAwLCJkIjowLCJtdCI6ImltYWdlL2pwZWciLCJkaCI6MTA0NzQzMDQ1MDY3MjMzMjE1MDUsImFiIjowfQ?format=originalOFGHLERTH"
                            alt=""
                          />
                        </a>
                            }
                    </div>
                    {
                            !isSimple &&
                            <div className={styleObj.down_bottom}>
                                {`${t('home.text1')}\n\n${t('home.text2')}\n\n${t('home.text3')}\n\n${t('home.text4')}`}
                            </div>
                        }
                  </div>
                </>
            }
      {
                isMobile &&
                <>
                  <div className={styleObj.line} />
                  <div className={styleObj.down}>
                    <div className={styleObj.down_top}>
                      <div>{t('home.getApp')}</div>
                      {
                                (!isAndroid || !isMobile) &&
                                <a target="_blank" href="https://omiapp.onelink.me/HCKs/g7zp1mmz" rel="noreferrer">
                                  <img
                                    src="https://auto.omicdn.com/v1/images/eyJpZCI6IjVYUlNEUkxaTkNQNlJCTloySUJCQ0xBTVhTWUM1ViIsInciOjM1MCwiaCI6MTAwLCJkIjowLCJtdCI6ImltYWdlL2pwZWciLCJkaCI6MTIzNzQ1MzQxNDE0Nzg0MCwiYWIiOjB9?format=originalOFGHLERTH"
                                    alt=""
                                  />

                                </a>
                            }
                      {
                                (isAndroid || !isMobile) && <a
                                  target="_blank"
                                  href="https://play.google.com/store/apps/details?id=sg.omi&hl=en&gl=US"
                                  rel="noreferrer"
                                >
                                  <img
                                    src="https://auto.omicdn.com/v1/images/eyJpZCI6IldOSjVRUk5IUVBWVUZQRkRUUE5BUkhTVE5IWk1KVSIsInciOjM1MCwiaCI6MTAwLCJkIjowLCJtdCI6ImltYWdlL2pwZWciLCJkaCI6MTA0NzQzMDQ1MDY3MjMzMjE1MDUsImFiIjowfQ?format=originalOFGHLERTH"
                                    alt=""
                                  />
                                </a>
                            }
                    </div>
                    {
                            !isSimple &&
                            <div className={styleObj.down_bottom}>
                                {`${t('home.text1')}\n\n${t('home.text2')}\n\n${t('home.text3')}\n\n${t('home.text4')}`}
                            </div>
                        }
                  </div>
                </>
            }
    </div>
  );
};

export default Download;
