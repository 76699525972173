import React from 'react';
import { ESPECIAL_PAY_TYPE } from '../const';

export const getAgentOs = () => {
  const uat = navigator.userAgent;
  if (uat.indexOf('Android') > -1 || uat.indexOf('Linux') > -1) {
    return 'android';
  } else if (uat.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    return 'ios';
  }
  return 'ios';
};

function parseJSON(response: any) {
  return response.json();
}

function checkStatus(response: any) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  throw error;
}

/**
 *
 * @param data {meta: {code: 200, message: "OK"}, data: {...}}
 * @returns {*}
 */
function checkMeta(data: any) {
  if (!data || !data.meta) {
    throw new Error('no meta data');
  } else {
    const { meta, ...d } = data;
    if (meta.code !== 200 && meta.code !== 201) {
      throw new Error(meta);
    } else {
      return d;
    }
  }
}

function request(url: any, options?: any) {
  if (!options) {
    options = {};
  }

  return fetch(url, { ...options, credentials: 'include' })
    .then(checkStatus)
    .then(parseJSON)
    .then(checkMeta);
}

export function post(url: any, data: any) {
  return request(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function get(url: any) {
  return request(url);
}

export function sendMail(mail: any) {
  return post('/api/mail', mail);
}

export function getSearchParams(search: string = '') {
  if (!search.length) return {};
  const obj: any = {};
  const paramArr = search.substring(1).split('&');
  paramArr.forEach((item) => {
    const itemArr = item.split('=');
    obj[itemArr[0]] = itemArr[1];
  });
  return obj;
}

const vipObj: any = {
  vip: 0,
  seeWhoLikedMe: 1,
  supreme: 2,
  diamond: 3,
};

export function filterPrice(data: any, tags: Array<string> = [], promotion?: boolean) {
  const filterKey = Object.keys(vipObj);
  const filterArr = data?.filter((item: any) => filterKey.includes(item.category)) || [];
  const reduceArr = filterArr.reduce((did: any, cur: any) => {
    const stockKeepUnit = promotion ? (cur.promotionStockKeepUnits?.[0] || {}) : (cur.defaultStockKeepUnits?.[0] || {});
    const prices = stockKeepUnit.prices || {};
    const tagPrice = prices.tagPrice || {};
    let price = tagPrice.default || 0;
    if (Array.isArray(tags) && tags.length > 0) {
      for (const tag of tags) {
        if (tagPrice[tag] > 0) {
          price = tagPrice[tag];
          break;
        }
      }
    }
    const { currencySymbol, currencyCode, inAppPrice } = prices;
    const month = cur.quantity;
    const price1 = price / month; // 月单价
    const obj = {
      ...cur,
      id: stockKeepUnit.id,
      currencySymbol, // 货币符号
      price, // 原始价格，不做展示，只用于计算
      price1: ESPECIAL_PAY_TYPE.includes(currencyCode) ? Math.ceil(price1) : price1?.toFixed(2), // 月单价
      month,
      vipType: vipObj[cur.category],
      inAppPrice,
      tagPrice,
    };
    // // 测试数据
    // if (did.length === 1) {
    //   obj.tag = 'bestDeal';
    // }
    did.push(obj);
    return did;
  }, []);
  return reduceArr;
}

export function getApi(url: string, headers: HeadersInit = {}) {
  const accessToken = localStorage.getItem('accessToken'); // 拿到用户的token
  // const accessToken = 'b3229e60a9472fcf2052279c35043e6af4df35c064a60d6b7fb53a7aa63bf981'; // 拿到用户的token
  // const path = `http://web.omi.dev.p1.cn${url}`;

  return fetch(url,
    {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((res) => res.json());
}

export function postApi(url: string, data: object, headers: HeadersInit = {}) {
  const accessToken = localStorage.getItem('accessToken'); // 拿到用户的token
  // const accessToken = 'b3229e60a9472fcf2052279c35043e6af4df35c064a60d6b7fb53a7aa63bf981'; // 拿到用户的token
  // const path = `http://web.omi.dev.p1.cn${url}`;
  return fetch(url,
    {
      method: 'POST',
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    }).then((res) => res.json());
}

export function sendPoint(params: object) {
  Object.defineProperty(
    params,
    'system',
    {
      value: getMobileOperatingSystem(),
      writable: true,
      configurable: true,
      enumerable: true,
    },
  );
  const accessToken = localStorage.getItem('accessToken'); // 拿到用户的token
  let url = '/web/event?';
  Object.entries(params).forEach((item) => {
    url += `${item[0]}=${item[1]}&`;
  });
  const init: any = {};
  if (accessToken) init.headers = { Authorization: `Bearer ${accessToken}` };
  fetch(url.slice(0, -1), init);
}

export function addLink(text: string, text1: string, text2: string) {
  const res = text.replace(text1, `<a href="https://omi.sg/privacy">${text1}</a>`)
    .replace(text2, `<a href="https://omi.sg/terms">${text2}</a>`);
  return <div dangerouslySetInnerHTML={{ __html: res }} />;
}

// 获取手机系统信息
export function getMobileOperatingSystem() {
  const { userAgent } = navigator;

  if (/android/i.test(userAgent)) {
    return 'Android';
  }
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'iOS';
  }
  return 'Unknown';
}

const countryCodeMap: any = {
  HKD: 'HK',
  TWD: 'TW',
  MYR: 'MY',
  THB: 'TH',
  IDR: 'ID',
};

// 苹果支付
export const handlePaymentApple = (
  {
    itemId,
    price,
    currencyCode,
    targetOrg,
    paymentMethod,
    upgradeOrderId,
  }: any,
  successCallback: Function,
  errorCallback: Function,
) => {
  const { ApplePaySession } = window as any;
  const applePaySession = new ApplePaySession(
    6, // 版本
    {
      countryCode: countryCodeMap[currencyCode],
      currencyCode,
      supportedNetworks: ['visa', 'masterCard'],
      merchantCapabilities: ['supports3DS'],
      total: {
        label: 'omi',
        amount: price,
      },
    },
  );
  applePaySession.begin();

  applePaySession.onvalidatemerchant = function (event: any) { // 验证商户
    const theValidationURL = event.validationURL;
    postApi(
      '/web/checkout/validate-applepay-session',
      { appleUrl: theValidationURL },
    )
      .then((response) => {
        applePaySession.completeMerchantValidation(response.data);
      });
  };

  applePaySession.onpaymentauthorized = function (event: any) { // 处理支付授权
    const applePaymentToken = event.payment.token;
    const params:any = {
      token: applePaymentToken,
      itemId,
      price,
      currencyCode,
      targetOrg,
      paymentMethod,
    };
    if (upgradeOrderId) {
      params.upgradeOrderId = upgradeOrderId;
    }
    postApi(
      '/web/checkout/pay',
      params,
    )
      .then((response) => {
        if (response.data) {
          applePaySession.completePayment(ApplePaySession.STATUS_SUCCESS);
          successCallback();
        } else {
          applePaySession.completePayment(ApplePaySession.STATUS_FAILURE);
          errorCallback();
        }
      });
  };
};

export function thousandsSeparator(n: number): string {
  const strSplit = n.toString().split('.');
  const integer = strSplit[0];
  const decimal = strSplit[1] || '';
  return integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + decimal;
}
